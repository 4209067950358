body {
  margin: 0;
  font-family: 'Inter',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 3px grey;*/
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  opacity: 0.6;
  border-radius: 10px;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 100;
}

.loader {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.rfs-option {
  padding: 0 0 0 10px!important;
  line-height: 35px;
  width: calc(100% - 10px) !important;
}

.search-wrapper.searchWrapper  {
  padding: 8px 13px;
  border-radius: 6px;
}

.search-wrapper.searchWrapper input::placeholder {
  color: rgb(110, 114, 118);
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.search-wrapper.searchWrapper input {
  padding: 0;
  margin: 0;
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}
